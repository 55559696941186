.slick-next:before {
    font-family: "Font Awesome 5 Free" !important;
    content: "\f053" !important;
    font-size: 15px;
}

.slick-prev:before {
    font-family: "Font Awesome 5 Free" !important;
    content: "\f054" !important;
    font-size: 15px;
}

.dots li {
    margin-left: 10px !important;
}