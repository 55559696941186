.wrap {
    transition: all 0.3s ease-in-out;
    flex-basis: 4%;
    position: relative;
}

@media (max-width: 1820px) {
    .wrap {
        flex-basis: 4%;
    }
}

@media (max-width: 1720px) {
    .wrap {
        flex-basis: 4%;
    }
}

@media (max-width: 1620px) {
    .wrap {
        flex-basis: 5%;
    }
}

@media (max-width: 1440px) {
    .wrap {
        flex-basis: 5.5%;
    }
}

@media (max-width: 1024px) {
    .wrap {
        flex-basis: 8.5%;
    }
}

@media (max-width: 965px) {
    .wrap {
        display: none;
    }
}

.aside {
    width: 300px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    transition: all 0.3s ease-in-out;
    align-items: flex-start;
    position: absolute;

}

.icon {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-left: 30px;
    width: 100%;
    height: 100px;
    cursor: pointer;
    box-shadow: 0 1px 4px 0 rgba(15, 15, 20, 0.1);
    background: linear-gradient(159deg, rgba(37, 37, 50, 0.98) 0%, rgba(35, 35, 45, 0.98) 100%);
}

@media (max-width: 1220px) {
    .icon {
        padding-left: 22px;
    }
}

.dote {
    margin-bottom: 5px;
    width: 20px;
    height: 2px;
    background: #8c8c8e;
    transition: all 0.5s ease-in-out;

}

.close {
    position: relative;

}

.line:first-child {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 2px;
    background: #8c8c8e;
    transform: rotate(45deg);
    transition: all 0.5s ease-in-out;
}

.line:last-child {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 2px;
    background: #8c8c8e;
    transform: rotate(-45deg);
    transition: all 0.5s ease-in-out;
}

.data {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    background: #20202b;
    text-transform: uppercase;
    padding-left: 13px;
    padding-top: 100px;
}

@media (max-width: 1220px) {
    .data {
        padding-left: 0px;
    }
}

.text {
    display: block;
    text-transform: uppercase;
    color: #8c8c8e;
    transform: rotate(90deg);
    cursor: pointer;
    letter-spacing: 2.5px;
    opacity: 1;
    transition: all 1s ease-in-out;
}

.navList {
    padding-top: 100px;
    padding-left: 25px;
    list-style: none;
    text-transform: uppercase;
    color: #8c8c8e;
    cursor: pointer;
    letter-spacing: 2.5px;
    opacity: 1;
    transition: all 0.8s ease-in-out;

}

.navList li {
    margin-bottom: 10px;
    transition: all 1s ease-in-out;
}

.navList li a {
    color: #8c8c8e !important;
    transition: all 0.5s ease;

}

.navList li a:hover {
   color: #ffc105 !important;
   transition: all 0.5s ease;
}

.lang {
    width: 100%;
    padding-left: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 200px;
    box-shadow: 0 1px 4px 0 rgba(15, 15, 20, 0.1);
    background: linear-gradient(159deg, rgba(37, 37, 50, 0.98) 0%, rgba(35, 35, 45, 0.98) 100%);
}

@media (max-width: 1220px) {
    .lang {
        padding-left: 20px;
    }
}

.ru {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border-width: 0;
    background: #1c1c27;
    color: #8c8c8e;
    font-size: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background 500ms ease-in-out;
}

.en {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border-width: 0;
    background: #1c1c27;
    color: #8c8c8e;
    font-size: 10px;
    cursor: pointer;
    transition: background 500ms ease-in-out;
}

.ruActive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border-width: 0;
    background: #ffc105;
    color: #1e1e29;
    font-size: 10px;
    margin-bottom: 10px;
    cursor: default;
    transition: background 500ms ease-in-out;
}

.enActive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border-width: 0;
    background: #ffc105;
    color: #1e1e29;
    font-size: 10px;
    cursor: default;
    transition: background 500ms ease-in-out;
}