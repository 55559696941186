.inactiveRightSidebar {
    transform: translateX(200px);
    display: none;
    animation: closeSidebarRight 0.5s ease-in-out;
}

.activeRightSidebar {
    display: flex;
    transform: translateX(0px);
    animation: openSidebarRight 0.5s ease-in-out;
}

@keyframes closeSidebarRight {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes openSidebarRight {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}