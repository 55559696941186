.progress {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: fit-content;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
}

.block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

@media (max-width: 425px) {
    .block {
        margin-bottom: 10px;
        width: 50%;
        justify-content: space-between;
    }
}

.number {
    display: flex;
    align-items: center;
    color: #ffc107;
    font-weight: 600;
    font-size: 28px;

}

.text {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-size: 15px;
    letter-spacing: 1px;
    color: white;
}

@media (max-width: 425px) {
    .text {
       margin-left: 20px;
    }
}

@media (max-width: 355px) {
    .text {
       margin-left: 7px;
       font-size: 13px;
    }
}
