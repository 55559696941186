.news {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.title {
    margin-bottom: 20px;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
}

.dots li {
    margin: 0 10px !important;
    cursor: default;
}

.prev {
    top: 104%;
    left: 98%;
    bottom: 0;
    right: 0;
    font-weight: 600;
    color: #888787;
    opacity: 0.5;
    cursor: pointer;
    z-index: 9;
}

.next {
    top: 104%;
    left: 95%;
    bottom: 0;
    right: 0;
    font-weight: 600;
    color: #888787;
    opacity: 0.5;
    cursor: pointer;
    z-index: 9;
}

@media (max-width: 700px) {
    .prev {
        left: 96%;
    }

    .next {
        left: 91%;
    }
}