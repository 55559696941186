.block {
    padding: 30px;
    flex-basis: 31.7%;
    background-color: #2f2f3c;
    font-family: 'Play-Regular';
    font-weight: normal;
    margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;
    border: 1px solid #2f2f3c;
}

.block:hover {
    border: 1px solid #ffc107;
}

@media (max-width: 1630px) {
    .block {
        flex-basis: 31.3%;
    }
}

@media (max-width: 1440px) {
    .block {
        flex-basis: 31%;
    }
}

@media (max-width: 1160px) {
    .block {
        flex-basis: 47%;
    }
}
@media (max-width: 670px) {
    .block {
        flex-basis: 99%;
    }
}

.title {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #fafafc;
    letter-spacing: 2px;
}

.text {
    color: #8c8c8e;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.5;
    margin-bottom: 20px;
}