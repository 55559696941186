.contacts {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    height: fit-content;
    
    margin-bottom: 20px;
}

.title {
    margin-bottom: 20px;
    color: white;
    margin-left: 10px;
    margin-right: 10px;

}

.location {
    background: #2c2c37;
    width: 49%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    color: #8c8c8e;
    padding: 30px;
}

.communication {
    background: #2c2c37;
    width: 49%;

    color: #8c8c8e;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.row a {
    color: #8c8c8e;
}

@media (max-width: 630px){
    .blocks {
        flex-direction: column;
        
    }
    .location {
        margin-bottom: 20px;
        width: 100%;
    }
    .communication {
        width: 100%;
    }
}