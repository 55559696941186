.button {
    display: inline-block;
    color: #ffc107;
    font-size: 12px;
    letter-spacing: 1.5px;
    font-weight: 600;
    line-height: 1rem;
    cursor: inherit;
    width: fit-content;
    transform: translateX(0px);
    transition: all .2s ease-in;
}

.button:hover {
    transform: translateX(10px);
    color: #ff0707;
}
