.block {
    background: linear-gradient(159deg, #2d2d3a 0, #2b2b35 100%);
    padding: 30px;
    margin-bottom: 20px;
    position: relative;
    width: 91%;
    font-family: 'Play-Regular';
    border: solid 1px #20202a;
    transition: all 0.5s ease-in-out;
}

.block:hover {
    border: solid 1px #ffc107;
    transition: all 0.5s ease-in-out;
}

.headerBlock {
    display: flex;
    justify-content: space-between;
}

.title {
    color: #fafafc;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    letter-spacing: 1.2px;
}

.underTitle {
    color: #646466;
    font-style: italic;
    font-size: 14px;
    margin-bottom: 15px;
}

.date {
    color: #646466;
    margin-left: auto;
    background: #20202a;
    padding: 5px 15px;
    border-radius: 15px;
    box-shadow: inset 0 3px 8px 0 rgba(15, 15, 20, .2);
    font-size: 10px;
}

.content {
    color: #8c8c8e;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.line {
    display: block;
    content: " ";
    width: 5px;
    height: 105%;
    top: 14%;
    right: -3.8%;
    position: absolute;
    background: #191923;
}

.lastLine {
    display: block;
    content: " ";
    width: 5px;
    height: 87%;
    top: 14%;
    right: -3.8%;
    position: absolute;
    background: #191923;
}

.circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: solid 3px #ffc107;
    position: absolute;
    right: -4.8%;
    top: 9%;
    z-index: 1;
}