.item {
    flex-basis: 31.5%;
    max-height: 285px;
    height: 285px;
    position: relative;
    margin-bottom: 30px;
    color: white;
    overflow-y: scroll;
    overflow: hidden;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    border: solid 1px #8c8c8e;
}

@media (max-width: 1520px) {
    .item {
        flex-basis: 31%;
    }
}

@media (max-width: 1160px) {
    .item {
        flex-basis: 47%;
    }
}
@media (max-width: 675px) {
    .item {
        flex-basis: 45%;
    }
}
@media (max-width: 530px) {
    .item {
        flex-basis: 97%;
    }
}

.loop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 8%;
    left: 8%;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    opacity: .5;
    cursor: pointer;
    transition: all .5s ease-in-out;
    background: -o-linear-gradient(291deg, rgba(45, 45, 58, .88) 0, rgba(43, 43, 53, .88) 100%);
    background: linear-gradient(159deg, rgba(45, 45, 58, .88) 0, rgba(43, 43, 53, .88) 100%);
}

.loopHidden {
    display: none;
}

.loop i {
    font-family: "Font Awesome 5 Free";
}

.loop:hover {
    opacity: 1;
    transform: scale(1.2, 1.2);
    transition: all .5s ease-in-out;
}

.loop i:before {
    content: "\f065";
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.7s ease-in-out;
}

.imageRais {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.imageRais img {
    width: 100%;
    height: 100%;
    transform: scale(1.3,1.3);
    object-fit: cover;
    transition: all 0.7s ease-in-out;
}

.moveBlock_hidden {
    position: absolute;
    bottom: -148px;
    left: 0;
    background: linear-gradient(159deg, rgba(45, 45, 58, .98) 0, rgba(43, 43, 53, .7) 100%);
    width: 100%;
    height: fit-content;
    transition: all 0.7s ease-in-out;
}

.moveBlock_visible {
    position: absolute;
    bottom: -116px;
    left: 0;
    background: linear-gradient(159deg, rgba(45, 45, 58, .98) 0, rgba(43, 43, 53, .7) 100%);
    width: 100%;
    height: fit-content;
    transition: all 0.4s ease-out;
    transform: translateY(-116px);
}

.title {
    padding: 15px 15px 0 15px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.5px;
}

.text {
    padding: 15px 15px 0 15px;
    color: #8c8c8e;
    font-size: 15px;
    letter-spacing: 1px;
}

.refference {
    padding: 15px 15px 15px 15px;
}

.refference a {
    text-decoration: none;
    color: #ffc107;
    font-size: 14px;
}