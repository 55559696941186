.myScrollPanel {
    width: 100% !important;
    position: relative;
    transition: all .7s ease-out;
    flex-basis: 75%;
    overflow: hidden;
    touch-action: none;
}

.myScrollPanel>main {
    transition: all 0.7s ease-out !important;
}

@media (max-width: 1620px) {
    .myScrollPanel {
        flex-basis: 74.1%;
    }
}

@media (max-width: 1024px) {
    .myScrollPanel {
        flex-basis: 100%;
    }
}

@media (max-width: 965px) {
    .myScrollPanel {
        flex-basis: 100%;
    }
}