.price {
    display: flex;
    flex-direction: column;
    
}

.title {
    color: white;
    margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;
}

.blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}