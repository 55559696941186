.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    padding: 0 15px;
}

@media (max-width: 1325px) {
    .wrapper {
        margin-top: 0px;
        padding: 0;
    }
}

.backgroundImage {
    background-image: url(../../images/bg.jpg);
    background-attachment: fixed;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: -1;

}

.background {
    background: #1e1e29;
    opacity: 0.2;
    width: 100%;
    height: 100%;
}

.myScrollPanel {
    width: 70% !important;
    position: relative;
    transition: all 0.3s ease-in-out;
    flex-basis: 75%;
}

@media (max-width: 1620px) {
    .myScrollPanel {
        flex-basis: 74.1%;
    }
}
@media (max-width: 1024px) {
    .myScrollPanel {
        flex-basis: 69%;
    }
}

@media (max-width: 965px) {
    .myScrollPanel {
        flex-basis: 100%;
    }
}

/* @media (max-width: 1820px) {
    .myScrollPanel {
        width: 1335px;
    }
}

@media (max-width: 1720px) {
    .myScrollPanel {
        width: 1260px;
        flex-basis: 75%;
    }
}

@media (max-width: 1620px) {
    .myScrollPanel {
        width: 900px;
        flex-basis: 74%;
    }
}

@media (max-width: 1520px) {
    .myScrollPanel {
        width: 1095px;
    }
}

@media (max-width: 1170px) {
    .myScrollPanel {
        width: 700px;
    }
}

@media (max-width: 1024px) {
    .myScrollPanel {
        max-width: 700px;
        flex-basis: 69%;
    }
}

@media (max-width: 1000px) {
    .myScrollPanel {
        width: 600px;
        flex-basis: 69%;
    }
}

@media (max-width: 965px) {
    .myScrollPanel {
        width: 600px;
        flex-basis: 100%;
    }
}

@media (max-width: 610px) {
    .myScrollPanel {
        width: 320px;
        flex-basis: 100%;
    }
} */