.block {
    font-family: 'Play-Regular';
    background-color: #2c2c37;
    position: relative;
    height: 250px;
    padding: 30px;
    margin-top: 30px;
    margin-bottom: 10px;
    transition: all 1s ease-in-out;
    margin-left: 10px;
    margin-right: 10px;
    width: 423px;
    border: 1px solid #2c2c37;
}

.block:hover {
    border: 1px solid #ffc107;
}

@media (max-width: 1820px) {
    .block {
        width: 380px;
        height: 270px;
    }
}

@media (max-width: 1720px) {
    .block {
        width: 380px;
        height: 270px;
    }
}

@media (max-width: 1620px) {
    .block {
        width: 350px;
        height: 280px;
    }
}

@media (max-width: 1520px) {
    .block {
        width: 320px;
        height: 280px;
    }
}

@media (max-width: 1440px) {
    .block {
        width: 323px;
        height: 280px;
    }
}

@media (max-width: 1420px) {
    .block {
        width: 295px;
        height: 300px;
    }
}

@media (max-width: 1320px) {
    .block {
        width: 295px;
        height: 300px;
    }
}

@media (max-width: 965px) {
    .block {
        width: 395px;
        height: 250px;
    }
}

@media (max-width: 375px) {
    .block {
        width: 300px;
        height: 300px;
    }
}

.avatar {
    position: absolute;
    right: 10%;
    top: -10%;
    width: 70px;
    height: 70px;
    border-radius: 0;
}

.title {
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: 600;
    color: #fafafc;
    letter-spacing: 2px;
}

.under_title {
    color: #646466;
    font-style: italic;
    font-size: 14px;
    margin-bottom: 15px;
}

.content {
    color: #8c8c8e;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.rating {
    background-color: #20202a;
    width: fit-content;
    padding: 0px 12px 4px 12px;
    border-radius: 25px;
}

.rating li {
    display: inline;
    list-style: none;
    line-height: 2;
    padding: 2px;
}

.rating li:before {
    font-family: "Font Awesome 5 Free";
    content: "\f005";
    color: #ffc107;
    font-size: 9px;
    font-weight: 900;
}

.source {
    color: #8c8c8e;
    font-size: 13px;
    transition: all 0.3s ease-in-out;

}
.source:hover {
    color: #00ff4c;
    transition: all 0.3s ease-in-out;
}

.review {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}