.reviews {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.title {
    color: white;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.dots li {
    margin: 0 10px !important;
    cursor: default;
}