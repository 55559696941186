.footer {
    margin-left: 10px;
    margin-right: 10px;
    color: #8c8c8e;
    margin-bottom: 10px;
}

.block {
    background-color: #2c2c37;
    position: relative;
    width: 100%;
    padding: 30px;
}

.content {
    margin-bottom: 0;
    font-size: medium;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.content span {
    display: block;
}

@media (max-width: 1024px) {
    .footer {
        margin-bottom: 40px;
    }
}

@media (max-width: 425px) {
    .content span {
        width: 100%;
        text-align: center;
        padding: 5px;
    }
}