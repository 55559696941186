i h3 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 25px;
}

p i {
    display: block;
    color: #fff;
    margin-bottom: .5rem;
    margin-top: 10px;
}

.backgroundImage {
    background-image: url(../../../../images/bg.jpg);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    width: 100%;
    height: 100vh;
    opacity: 0.7;
    z-index: -1;
    top: 0;
}

.background {
    background: #1e1e29;
    opacity: 0.9;
    width: 100%;
    height: 100%;
}

.foto {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    height: 300px;
    overflow: hidden;
    transition: border 0.5s ease-in-out;
    border: 1px solid #2c2c37;

}

@media (max-width: 510px) {
    .foto {
        height: 100%;
    }
}

.foto img {
    width: 100%;
    object-fit: cover;
}

.aside {
    flex-basis: 31%;
}

.content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 20px;
}

.article {
    margin-right: 20px;
    margin-bottom: 20px;
    background: #2c2c37;
    padding: 30px;
    flex-basis: 69%;
    line-height: 1.45em;
    border: 1px solid #2c2c37;
    transition: border 0.5s ease-in-out;
}

.article:hover {
    border: solid 1px #ffc107;
    transition: border 0.5s ease-in-out;
}

.author {
    display: flex;
    background: #2c2c37;
    justify-content: space-between;
    padding: 30px;
    color: #8c8c8e;
    margin-bottom: 30px;
    border: 1px solid #2c2c37;
    transition: border 0.5s ease-in-out;
}

.author:hover {
    border: solid 1px #ffc107;
    transition: border 0.5s ease-in-out;
}

@media (max-width: 1100px) {
    .content {
        flex-direction: column;
    }

    .article {
        margin-right: 0px;
    }

    .author {
        margin-right: 0px;
    }
}

.author ul li {
    list-style: none;
    margin-bottom: 6px;
}

.rightBlock {
    text-align: right;
}

.title {
    color: white;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 25px;
    margin-top: 10px;
    margin-left: 20px;
}

.text {
    margin-bottom: 30px;
    color: #8c8c8e;
}

.btn {
    display: block;
}

.footer {
    padding: 10px;
}

.instagram {
    margin-bottom: 20px;
}

.form {
    margin-left: -10px !important;
    margin-right: -10px !important;
}