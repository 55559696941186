.backgroundImage {
    background-image: url(../../../images/bg.jpg);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    width: 100%;
    opacity: 0.7;
    z-index: -1;
    height: 100vh;
    top: 0;
}

.background {
    background: #1e1e29;
    opacity: 0.9;
    width: 100%;
    height: 100%;
}

.main {
    position: relative;
    display: flex;
    flex-basis: 80%;
    flex-direction: column;
    width: 100%;
}