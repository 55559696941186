.inactiveMobileSidebar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 300px;
    transform: translateX(-300px);
    transition: all 0.5s ease-in-out;
    animation: close 0.5s ease-in-out;
}

.activeMobileSidebar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 300px;
    transition: all 0.5s ease-in-out;
    animation: open 0.5s ease-in-out;
}

@keyframes close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes open {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}