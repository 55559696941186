.sidebar {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    /* min-width: 290px; */
    z-index: 2;
    flex-basis: 20%;
    background: #20202a;
    transform: translateX(0px);
    transition: all 0.3s ease-out;
}

@media (max-width: 1790px) {
    .sidebar {
        flex-basis: 21%;
        transform: translateX(0px);
    }
}

@media (max-width: 1280px) {
    .sidebar {
        flex-basis: 20%;
    }
}

@media (max-width: 1024px) {
    .sidebar {
        flex-basis: 35%;
    }
}

@media (max-width: 965px) {
    .sidebar {
    display: none;
        /* display: none;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateX(-300px);
        transition: all 0.5s ease-in-out; */
    }
}

.closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    animation: rotate ease-in-out 0.7s;
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.closeIcon .line:first-child {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 2px;
    background: #ffc107;
    transform: rotate(45deg)
}

.closeIcon .line:last-child {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 2px;
    background: #ffc107;
    transform: rotate(-45deg);
}

.fotos {
    background: linear-gradient(159deg, rgba(37, 37, 50, .98) 0, rgba(35, 35, 45, .98) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;

}

.myAvatar {
    background: url(../../../images/ava.jpg) center/cover no-repeat;
    width: 100px;
    height: 100px;
    border-radius: 60px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.name {
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
    color: white;
    letter-spacing: 2px;
}

.whoiam {
    color: #8c8c8e;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 1.5;
    margin-bottom: 40px;
}

.scrollBar {
    width: 100%;
}

.knowledge {
    transition: all .3s ease-out;
    padding: 15px 30px;
    background: #20202a;
    width: 100%;
}


.info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.hr {
    text-align: center;
    height: 1px;
    margin-top: 15px;
    background: #646466;
    width: 100%;
    opacity: .3;
}

.language {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;

}

.skills {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.right {
    text-align: right;
    color: #8c8c8e;
    display: inline-block;
}

.sidebar .knowledge .skills .title_skill span {
    display: inline-block;
    font-size: small;
}

.circle_lang {
    margin-top: 20px;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    border: #ffc107 solid 3px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8c8c8e;
    font-size: 12px;
    margin-bottom: 10px;
}

.protsenty {}

.skill {
    color: white;
}

.insrumental {
    color: #8c8c8e;
    margin-top: 20px;
}

.download {
    margin-top: 20px;

}

.sidebar .knowledge .download a {
    color: #8c8c8e;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
}

.sidebar .knowledge span {
    display: block;
    padding: 5px 0;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: .5px;
}

.sidebar .knowledge .download span {
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 15px;
}

.load {
    font-family: "Font Awesome 5 Free";
}

.title_skill {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    letter-spacing: .5px;
    font-size: 14px;
}

.inline {
    width: 100%;
    background: #131313;
    height: 7px;
    margin-bottom: 20px;
    padding: 2px 0;
}

.line_skill {
    width: 90%;
    background: #ffc107;
    height: 4px;
    width: 89%;
}

.sidebar .fotos {
    background: -o-linear-gradient(291deg, rgba(37, 37, 50, .98) 0, rgba(35, 35, 45, .98) 100%);
    background: linear-gradient(159deg, rgba(37, 37, 50, .98) 0, rgba(35, 35, 45, .98) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.social_icons {
    padding: 10px;
}

.social_icons a {
    text-decoration: none;
}

.sidebar .social_icons span {
    display: inline-block;
    font-family: "Font Awesome 5 Brands";
    padding: 5px 10px;
}

.sidebar .social_icons span.insta:before {
    content: "\f16d";
}

.sidebar .social_icons span:before {
    font-family: "Font Awesome 5 Brands";
    content: "\f0e1";
    font-size: 16px;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    color: #8c8c8e;
}

.sidebar .social_icons span.vk:before {
    content: "\f189";
}

.sidebar .social_icons span:before {
    font-family: "Font Awesome 5 Brands";
    content: "\f0e1";
    font-size: 16px;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    color: #8c8c8e;
}

.sidebar .social_icons span.linkin:before {
    content: "\f0e1";
}

.sidebar .social_icons span:before {
    font-family: "Font Awesome 5 Brands";
    content: "\f0e1";
    font-size: 16px;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    color: #8c8c8e;
}

.sidebar .social_icons span.whatsapp:before {
    content: "\f232";
}

.sidebar .social_icons span.telegram:before {
    content: "\f2c6";
}

.sidebar .social_icons span:before {
    font-family: "Font Awesome 5 Brands";
    content: "\f0e1";
    font-size: 16px;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    color: #8c8c8e;
}

.sidebar .social_icons span.skype:before {
    content: "\f17e";
}

.sidebar .social_icons span:before {
    font-family: "Font Awesome 5 Brands";
    content: "\f0e1";
    font-size: 16px;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    color: #8c8c8e;
}

.sidebar .knowledge .download .load:before {
    font-family: "Font Awesome 5 Free";
    content: "\f019";
    font-weight: 800;
    font-size: 12px;
}

.sidebar .knowledge .insrumental span:before {
    font-family: "Font Awesome 5 Free";
    font-size: 9px;
    font-weight: 900;
    content: "\f00c";
    color: #ffc107;
}

.sidebar .knowledge .download a {
    color: #8c8c8e;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
}

.sidebar .social_icons span:hover:before {
    color: #fafafc;
    cursor: pointer;
}

.sidebar .knowledge .download a:hover {
    color: #fafafc;
    text-shadow: 0 0 3px rgba(250, 250, 252, .4);
}