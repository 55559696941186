/* MAIN */

@font-face {
    font-family: 'Play-Regular';
    src: local('Play-Regular'), url('./fonts/Play-Regular.ttf') format('truetype');
    font-display: swap;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Play-Regular';
}

body {
    background: #1e1e29;
    overflow: hidden;
}

a{
    text-decoration: none;
}

.main {
    position: relative;
    display: flex;
    flex-basis: 80%;
    flex-direction: column;
    width: 100%;
}

.container {
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 1920px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    margin-top: 15px;
    transition: all .3s ease-in-out;
}

@media (max-width: 1325px) {
    .container {
        margin: 0;
    }
}

.news-block {
    width: 32%;
    background-color: #2c2c37;
    position: relative;
    margin-bottom: 20px;
    border: 1px solid #2c2c37;
    transition: all 0.5s ease-in-out;
}

@media (max-width: 1024px) {
    .news-block {
        width: 49%;
    }
}

@media (max-width: 500px) {
    .news-block {
        width: 100%;
    }
}

@media (max-width: 1720px) {
    /* .container {
        width: 1690px;
    } */

    .slick-track {
        width: 9614px;
    }
}

@media (max-width: 1980px) {
    .slider {
        height: 300px !important;
    }
    .sliderNews {
        height: 410px !important;
    }
}

@media (max-width: 1822px) {
    .slider {
        height: 320px !important;
    }
    .sliderNews {
        height: 410px !important;
    }
}

@media (max-width: 1420px) {
    .slider {
        height: 330px !important;
    }
    .sliderNews {
        height: 410px !important;
    }
}

@media (max-width: 1320px) {
    .slider {
        height: 340px !important;
    }
    .sliderNews {
        height: 410px !important;
    }
}

@media (max-width: 965px) {
    .slick-track {
        width: 9614px !important;
    }
}

@media (max-width: 965px) {
    .slider {
        height: 300px !important;
    }
    .sliderNews {
        height: 410px !important;
    }
}

@media (max-width: 375px) {
    .slider {
        height: 335px !important;
    }
}

:active,
:hover,
:focus {
    outline: 0;
    outline-offset: 0;
}

.p-scrollpanel-bar-y,
.p-scrollpanel-bar-x {
    width: 0px;
    display: none !important;
}

.p-scrollpanel-content {
    padding: 0 0 0 0 !important;
}

.component-enter {
    opacity: 0;
}

.component-enter-active {
    opacity: 0;
}

.component-enter-done {
    opacity: 1;
    transition: all 0.7s ease
}

.component-exit {
    opacity: 1;
}

.component-exit-active {
    opacity: 0;
    transition: all 0.3s ease
}

.component-exit-done {
    opacity: 0;
}

.component-appear {
    opacity: 0;
}

.component-appear-active {
    opacity: 1;
    transition: all 0.5s ease
}

/* Article */

ol {
    margin-left: 30px;
    margin-top: 15px;
    line-height: 1.8rem;
}

.article h2 {
    margin-bottom: 20px;
    line-height: 1.8rem;
}
.article h3 {
    color: white;
    font-size: 1rem;
    font-weight: normal;
    margin-top: 20px;
}

.article p {
    padding-top: 10px;
    margin-left: 30px;
    line-height: 1.8rem;
}

@media (max-width: 425px) {
    .article p {
        line-height: 1.6rem;
        margin-left: 0px;
    }
}