.block {
    padding: 30px;
    background-color: #2c2c37;
    flex-basis: 31.5%;
    font-family: 'Play-Regular';
    font-weight: normal;
    margin-bottom: 30px;
    margin-right: 10px;
    margin-left: 10px;
    border: 1px solid #2c2c37;
    transition: all 0.5s ease-in-out;
}
.block:hover {
    border: 1px solid #ffc107;
    transition: all 0.5s ease-in-out;
}
@media (max-width: 1520px) {
    .block {
        flex-basis: 30.5%;
    }
}
@media (max-width: 1440px) {
    .block {
        flex-basis: 31%;
    }
}
@media (max-width: 1160px) {
    .block {
        flex-basis: 96%;
    }
}

.title {
    text-align: center;
    color: #fafafc;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    letter-spacing: 2px;
}

.rate {
    color: #ffc107;
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: .5px;
    text-transform: uppercase;
    text-align: center;
}

.rate h4 {
    font-size: 34px;
}

.rate span {
    font-size: 15px;
}

.listServices ul {
    list-style: none;
    color: #8c8c8e;
    font-size: 12px;
    font-weight: 600;
    margin: 20px 0;
    text-align: center;
    font-size: 14px;
}

.listServices ul li {
    line-height: 2.9;
    opacity: 0.5;
    letter-spacing: 2.2px;
}

.listServices ul li:hover {
    color: silver;
}

.listServices ul li:nth-child(-n+3) {
    opacity: 1;
}

.listServices ul li:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f00c";
    margin-right: 5px;
    font-size: 9px;
    color: #ffc107;
}

.listServices ul li:nth-child(-n+3):before {
    content: "\f00c";
}

.button {
    text-align: center;
    cursor: pointer;
}

.footnote {
    text-align: left;
    opacity: .5;
    font-size: 11px;
    margin: 15px -10px 0 -10px;
    color: #fafafc;
    line-height: 1.4;
}