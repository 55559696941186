.title {
    color: white;
    font-size: 17px;
    font-weight: 600;
    padding: 20px 30px 30px 30px;
}

.backgroundImage {
    background-image: url(../../../images/bg.jpg);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    width: 100%;
    height: 100vh;
    opacity: 0.7;
    z-index: -1;
    top: 0;
}

.background {
    background: #1e1e29;
    opacity: 0.9;
    width: 100%;
    height: 100%;
}

.main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    min-height: 1000px;
}

.articles {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
}

.paginate {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    color: #8c8c8e;
    padding: 20px;
    margin: 0 30px 0 30px;
}

.page {
    cursor: pointer;
    padding: 0 10px;
}

.activePage {
    color: #ffc107;
    padding: 10px;
}

.nextPage,
.prevPage {
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.prevPage:hover {
    color: #ffc107;
    transform: translateX(-15px);
    transition: all 0.5s ease-in-out;
}

.nextPage:hover {
    color: #ffc107;
    transform: translateX(+15px);
    transition: all 0.5s ease-in-out;
}

.footer {
    padding: 20px;
}