.navbar {
    display: none;
    width: 100%;
    background: #1e1e29;
    color: silver;
}

@media (max-width: 965px) {
    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.leftIcon {
    margin: 15px;
    margin-left: 20px;
    cursor: pointer;
}

.rightIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}

.dote {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #8c8c8e;
    margin: 2px;
}

.line {
    width: 20px;
    height: 2px;
    background: #8c8c8e;
    margin: 3px;
}