.wrap {
    height: fit-content;
}

.myHistory {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 10px;
}

@media (max-width: 630px){
    .myHistory {
        flex-direction: column;
    }
}

.content {
    display: flex;
    flex-direction: column;
    flex-basis: 49.5%;
}

.title {
    color: white;
    margin-bottom: 20px;
}

.blocks {
    display: flex;
    flex-direction: column;
}