.block {
    width: 324px;
    background-color: #2c2c37;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    border: 1px solid #2c2c37;
    transition: all 0.5s ease-in-out;
}

.block:hover {
    border: 1px solid #ffc107;
    transition: all 0.5s ease-in-out;
}

.imgPreview {
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgPreview img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: all 0.7s ease-in-out;
}

.imgPreview img:hover {
    cursor: pointer;
    width: 130%;
    height: 130%;
    transition: all 0.7s ease-in-out;
}

.contentBlock {
    padding: 30px;
    color: #8c8c8e;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.5;
}

.title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #fafafc;
}

.content {
    margin-bottom: 20px;
}