.offer {
    position: relative;
    height: 300px;
    margin-bottom: 30px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}

.bgImg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: url('../../../../images/bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    opacity: 0.5;
}

@media (max-width: 425px) {
    .offer {
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 15px;
    }
}

.title * {
    display: block;
    letter-spacing: 1.6px;
    line-height: 1.2em;
    color: #fafafc;
    font-size: 42px;
    font-weight: 800;
    text-align: center;
    padding-top: 30px;
}

@media (max-width: 1170px) {
    .title * {
        font-size: 35px;
    }
}

@media (max-width: 625px) {
    .title * {
        font-size: 30px;
    }
}

@media (max-width: 550px) {
    .title * {
        font-size: 25px;
        margin-top: 22px;
    }
}

@media (max-width: 425px) {
    .title * {
        font-size: 25px;
    }
}

@media (max-width: 320px) {
    .title * {
        font-size: 21px;
        padding: 40px 5px 0px 5px;
    }
}

.code {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 200;
    text-align: center;
    color: #ffc107;
    margin-top: 8px;
    height: 50px;

}

@media (max-width: 1170px) {
    .code {
        font-size: 20px;
        padding: 0 20px;
    }
}

@media (max-width: 625px) {
    .code {
        font-size: 18px;
        padding: 0 10px;
        margin-top: 15px;
    }
}

@media (max-width: 425px) {
    .code {
        font-size: 16px;
        padding: 0 10px;
        margin-top: 10px;
    }
}

@media (max-width: 320px) {
    .code {
        margin-top: 7px;
    }
}


.button {
    display: block;
    margin-top: 30px;
    cursor: pointer;
    text-align: center;
    margin-top: 40px;
    transition: all .5s ease-in-out;
    display: flex;
    justify-content: center;
}

@media (max-width: 625px) {
    .button {
        margin-top: 60px;
    }
}

@media (max-width: 500px) {
    .button {
        margin-top: 70px;
    }
}

@media (max-width: 395px) {
    .button {
        margin-top:75px;
    }
}

.button:hover {
    transform: translateY(5px);

}

.button span {
    width: fit-content;
    color: #20202a;
    letter-spacing: 1.5px;
    font-weight: bold;
    font-size: 12px;
    padding: 15px 25px;
    background-color: #ffc107;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    width: 188px;
    display: block;
}

@media (max-width: 625px) {
    .button span {
        margin-top: -20px;
        padding: 10px 15px;
    }
}

.line {
    display: inline-block;
    height: 20px;
    width: 1px;
    background: white;
    margin-top: 3px;
}