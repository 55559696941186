.formOrder {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.title {
    margin-bottom: 20px;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
}


.block {
    background-color: #2c2c37;
    position: relative;
    padding: 30px;
    margin-left: 10px;
    margin-right: 10px;
}

.error {
    color: red;
    margin-top: -20px;
    margin-bottom: 5px;
}

.inputSection {
    display: flex;
    margin-bottom: 25px;
}

.inputSectionError {
    display: flex;
    margin-bottom: 25px;
    border: red solid 1px;
    transition: all 1s ease-in-out;
}

label {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #20202a;
}

label i {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    text-align: center;
    color: #8c8c8e;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 11px;
}

label.textarea {
    width: 50px;
    height: 150px;
    display: flex;
    justify-content: center;
    background: #20202a;
}


label i.name:before {
    content: "\f007";
}

label i.mail:before {
    content: "\f1fa";
}

label.textarea i:before {
    content: "\f0e0";
}

input {
    height: 50px;
    width: 100%;
    outline: 0;
    border: 0;
    background: -o-linear-gradient(291deg, #252532 0, #23232d 100%);
    background: linear-gradient(159deg, #252532 0, #23232d 100%);
    padding: 15px;
    color: #fafafc;
    font-size: 16px;
}

textarea {
    display: inline-block;
    width: 100%;
    height: 150px;
    outline: 0;
    border: 0;
    background: -o-linear-gradient(291deg, #252532 0, #23232d 100%);
    background: linear-gradient(159deg, #252532 0, #23232d 100%);
    padding: 15px;
    color: #fafafc;
    font-size: 16px;
}

.btnOffer {
    width: fit-content;
    display: inline-block;
    color: #20202a;
    letter-spacing: 1.5px;
    font-weight: 600;
    font-size: 13px;
    padding: 15px 25px;
    background-color: #ffc107;
    text-transform: uppercase;
    margin-top: 30px;
    cursor: pointer;
    transition: all .5s ease-in-out;
    border: 0;
}

.btnOffer:hover {
    transform: translateY(5px);
}